no-photos {

  .scroll-bar-spacer{
    width: 25px;
  }

  .reports-table{

    td:nth-child(1) {
      width: 15%;
    }

    td:nth-child(2) {
      width: 70%;
    }

    td:nth-child(3) {
      width: 15%;
    }

    .description{
      //font-size: 0.8em;
      text-transform: capitalize;
    }


  }

}