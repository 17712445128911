items-structure {

  .items-structure-details {
    md-checkbox {
      margin: 0 !important;
    }

    input {
      width: 60px;
      border: none;
      margin-left: 5px;
      padding: 2px;
      background: #e1e1e1;
    }

  }


  .draft-online a {
    color: green;
  }

  .draft-offline a {
    color: red;
  }
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

}
