// ====================================
// Common variables
// ====================================

$primary-color: #000000;
$light-background:#EEEEEE;
$secondary-color: #B7B4B4;
$text-color: #707070;
$text-contrast: #ffffff;
$accent-color: #b2b2b2;
$accent-color-transparent: rgba(232, 3, 3, 0.25);

$radius-sm: 5px;
$radius-md: 10px;
$radius-lg: 15px;

// ====================================
// Media Query Breakpoints
// ====================================

$for-phone-only: 599px;
$for-tablet-portrait-up: 600px;
$for-tablet-landscape-up: 900px;
$for-desktop-up: 1200px;
$for-big-desktop-up: 1800px;

// ====================================
// Common Fonts Settings
// ====================================
$primary-font-family: "Open Sans", Arial, sans-serif;
$primary-font-color: #000000;
