cropper-js {

  /* Limit image width to avoid overflow the container */
  cropper-js img {
    max-width: 100%; /* This rule is very important, please do not ignore this! */
  }

  /*cropper-js #cropperJsImg {*/
  /*    width: 50%; !* This rule is very important, please do not ignore this! *!*/
  /*}*/
  .preview {
    overflow: hidden;
    width: 213px;
    height: 160px;
    border: 2px grey solid;
    margin-left: 20px;
    background: #ffffff;
  }

  .cropper-img-cont {
    width: 640px;
    height: 480px;
  }

}
