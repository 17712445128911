.reports-components{

  .reports-table{

    width: 100%;

    thead td {
      border-bottom: solid 1px #000000;
      font-weight: bold;
    }

    td {
      height: 1.5em;
    }

    td .mouse-floating-icon {
      display: none;
      margin: 0 2px;
      cursor: pointer;
    }

    td:hover .mouse-floating-icon {
      display: inline-block;
    }
  }

}

.reports-headers {
  margin: 8px 0 4px 0;
  border-bottom: 1px solid #0d0d0d;
  font-weight: bold;
}
.reports-scrollbar-spacer {
  width: 17px;
}

.reports-row-switch {
  margin: 0;
  font-size: 12px;
}

.reports-row-button {
  margin: 0 !important;
  padding: 0 !important;
  height: 24px !important;
  min-height: 24px !important;
}



// ====================================
// Reports Import
// ====================================

@import 'ng-app/admin-app/website-admin/reports/no-cp/no-cp.scss';
@import 'ng-app/admin-app/website-admin/reports/no-photos/no-photos.scss';
@import 'ng-app/admin-app/website-admin/reports/cp-orphans/cp-orphans.scss';
@import 'ng-app/admin-app/website-admin/reports/zero-prices/zero-prices.scss';
