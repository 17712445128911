.toast-info .md-toast-content, .toast-info {
  background-color: #fff34d;
  font-size: 14px;
  max-width: 600px;
}

.toast-error .md-toast-content{
  background-color: #ffaa7e;
  font-size: 14px;
  max-width: 600px;
}

.toast-error .error-details {
  font-size: 14px;
  background-color: #ffaa7e;
  max-width: 252px;
  /*max-height: 30px;*/
  padding: 4px;
  //margin: 10px 0;
}

