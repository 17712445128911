.order-status-pending {
    background-color: #ffce3b;
  }

.order-status-completed {
  background-color: #397ee8;
}

.order-status-exported{
  background-color: #43e841;
}

.order-status-cxl {
  background-color: #858585;
}

.order-status-error {
  background-color: #ff3c3c;
}

