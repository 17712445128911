items-media-bin {
  img {
    width: 100%;
  }

  li {
    /*background-color: #fff;*/
    /*border: 2px solid #686868;*/
    /*-webkit-clip-path: inset(20%);*/
    /*clip-path: inset(20%);*/
    display: inline-block;
    list-style-type: none;
    vertical-align: middle;
    margin: 5px;
    position: relative;
  }

  md-icon.large-icon {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;

  }

  .bin-button {
    width: 100%;
    position: absolute;
    //z-index: 5;
    top: 20px;
    left: 0;
    display: none;
    background-color: rgba(255, 255, 255, 0.75);
  }
  li:hover .bin-button {
   display: block;
  }

  .img-container {
    border-radius: 2px;
    -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.49);
    -moz-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.49);
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.49);
    height: 96px;
    width: 128px;
    padding: 4px;
    text-align: center;
  }

  .media-description {
    height: 30px;
    width: 128px;
    padding: 4px;
    font-size: 12px;
    background-color: #ececec;
    margin: 5px 3px 0 0;
    text-align: center;
    overflow-wrap: break-word;

  }
}
