
// ====================================
// Import
// ====================================

// Root
@import 'ng-app/admin-app/root/variables.scss';
@import 'ng-app/admin-app/root/mixins.scss';
@import 'ng-app/admin-app/root/common.scss';

@import 'ng-app/admin-app/root/root.scss';

// rds-side-nav-right
@import 'ng-app/admin-app/root/rds-side-nav-right.scss';

// Components
@import 'ng-app/admin-app/components/specs-edit/specs-edit.scss';
@import 'ng-app/admin-app/components/items-media-upload/items-media-upload.scss';
@import 'ng-app/admin-app/components/cropper-js/cropper-js.scss';
@import 'ng-app/admin-app/components/picto/picto.scss';



// toast (mdToast)
@import 'ng-app/admin-app/toast/toast.scss';

// Items
@import 'ng-app/admin-app/website-admin/items/items-list.scss';
@import 'ng-app/admin-app/website-admin/items/items-details.scss';
@import 'ng-app/admin-app/website-admin/structure/structure.scss';
@import 'ng-app/admin-app/components/items-media-photo/items-media-photo.scss';
@import 'ng-app/admin-app/components/items-media-youtube/items-media-youtube.scss';
@import 'ng-app/admin-app/components/items-media-document/items-media-document.scss';
@import 'ng-app/admin-app/components/items-media-bin/items-media-bin.scss';
@import 'ng-app/admin-app/website-admin/items/items-structure.scss';
@import 'ng-app/admin-app/website-admin/items/items-search-dlg/items-search-dlg.scss';

// Catalogs Templates
@import 'ng-app/admin-app/catalog-admin/draft-catalog/draft-catalog.scss';
@import 'ng-app/admin-app/catalogs-templates/catalogs-styles.scss';
@import 'ng-app/admin-app/catalog-admin/draft-catalog/draft-item-component/draft-item.component.scss';

// Reports
@import 'ng-app/admin-app/website-admin/reports/reports.scss';
@import 'ng-app/admin-app/website-admin/no-cp/no-cp.scss';

// Orders
@import 'ng-app/admin-app/web-orders-mgr/orders/orders-list.scss';
@import 'ng-app/admin-app/web-orders-mgr/orders/orders-details-tabs/orders-items-table.scss';

// Items Creator
@import 'ng-app/admin-app/components/items-creator/items-creator.scss';

