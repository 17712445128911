items-media-photo {
  img {
    width: 100px;
  }

  .dndDraggingSource {
    display: none;
  }

  .dndPlaceholder {
    /*background-color: #dfe7f6;*/
    /*height: 80px;*/
  }


}

.itemMediaCnt {
}

.itemMediaCnt ul[dnd-list], .dnd-trash ul[dnd-list] {
  min-height: 80px;
  padding-left: 0px;
  margin: 0;
  display: inline-block;
}

.itemMediaCnt ul[dnd-list] .dndDraggingSource, .dnd-trash ul[dnd-list] .dndDraggingSource {
  display: none;
}

.itemMediaCnt ul[dnd-list] .dndPlaceholder {
  background-color: #90b4ce;
  /*height: 80px;*/
  /*width: 100px;*/
  /*margin: 15px;*/
}

.dnd-trash ul[dnd-list] li {
  /*background-color: #fff;*/
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.itemMediaCnt ul[dnd-list] li {
  /*background-color: #fff;*/
  /*border: 2px solid #686868;*/
  border-radius: 2px;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.49);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.49);
  /*-webkit-clip-path: inset(20%);*/
  /*clip-path: inset(20%);*/
  display: inline-block;
  list-style-type: none;
  vertical-align: middle;
  margin: 5px;
  height: 77px;
  width: 100px;
  line-height: 74px;
  text-align: center;
  position: relative;

  .view-image {
    position: absolute;
    bottom: -28px;
    font-size: 12px;
    left: 2px
  }

  .catalog-image {
    position: absolute;
    top: -25px;
    font-size: 12px;
    right: 2px
  }


}

/**
 * Show selected elements in green
 */
.itemMediaCnt ul[dnd-list] li.selected {
  background-color: #dff0d8;
  color: #3c763d;
}

.itemMediaCnt img {
  width: 94%;
  vertical-align: middle;
}

.toolbox-table {

  td {
    width: 33%;
    text-align: center;
    vertical-align: middle;
  }

  .dragover, .dndDragover {
    color: $accent-color;
  }

  .fa {
    font-size: 4em;

  }

  li {
    list-style: none;
  }

  textarea {
    height: 50px;
  }

  .text-info {
    vertical-align: top;
  }
}




