

// =============
// Make comma separated list
// =============
.comma-list {
  display: inline;
  list-style: none;
  padding: 0px;

  li {
    display: inline;
    margin: 0;
    padding: 0;

    &::after {
      content: ", ";
    }

    &:last-child::after {
      content: "";
    }
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

// =============
// Hover Icon
// =============
.mouseover-floating-icon-container {
  position: relative;
  padding-right: 2px;

}

.mouseover-floating-icon {
  position: absolute;
  right: -24px;
  top: -5px;
  display: none;
  cursor: pointer;
}

.click-to-copy {
  cursor: copy;
  &:hover{
    color: #399dff !important;
  }
}

.mouseover-floating-icon-container:hover > .mouseover-floating-icon {
  display: inline-block;
}

// =============
// Other
// =============

.clear-search-button {
  margin: 8px 1px 1px -14px !important;
}
.adv-search-button {
  margin: 8px 1px 1px 0px !important;
}

.translate-icon {
  cursor: pointer;
}

.tiny-caption-text {
  font-size: 12px;
  font-style: italic;
}

trix-toolbar button.underline {
  font-size: 18px;
  vertical-align: bottom;
  text-decoration: underline;
}

inline-input {
  display: flex;

  div {
    min-height: 20px;
    position: relative;
    display: flex;
    flex:1 1 auto;

    input {
      flex:1 1 auto;
    }
  }

}
