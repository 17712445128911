items-media-upload {



  md-icon {
    font-size: 36px !important;
    height: 36px !important;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  .upload-ext {

    position: relative;

    textarea {
      width: 100%;
      display: block;
      position: absolute;
      border: none;
      background-color : transparent;
      z-index: 10;
      left: 0;
      top: 0;
    }


  }
}
