.rds-side-nav-right {

  .rds-list-item {
    line-height: 14px;
    font-size: 12px;
    padding: 5px;
  }

  .rds-list-item h2 {
    font-size: 12px;
    margin: 3px auto;
  }

  .rds-list-item p {
    margin: 3px auto;
  }

  md-input-container {

  }

  .md-errors-spacer {
    display: none;
  }

}

