.structure {

  li {
    list-style: none;
    text-transform: uppercase;
  }

  .str-chapter, .str-section, .str-category {

    @include border-radius-combined($radius-sm);
    margin-top: 10px;
    min-height: 40px;
    padding: 3px;
  }

  .str-chapter {
    background-color: #b2b2b2;

    @include border-radius-combined($radius-sm);
  }

  .str-section {
    background-color: #cccccc;

  }

  .str-category {
    background-color: #eeeeee;
    padding-left: 15px;

  }

  md-checkbox {
    margin: 0;
  }

  /**
   * For the correct positioning of the placeholder element, the dnd-list and
   * it's children must have position: relative
   */
  ul[dnd-list],
  ul[dnd-list] > li {
    position: relative;
  }

  /**
   * The dnd-list should always have a min-height,
   * otherwise you can't drop to it once it's empty
   */
  ul[dnd-list] {
    min-height: 42px;
    //padding-left: 0px;
  }

  /**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
  ul[dnd-list] .dndDraggingSource {
    display: none;
  }

  /**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
  ul[dnd-list] .dndPlaceholder {
    border: 4px dashed #5e5e5e;
    //display: block;
    //background-color: #6bc2ef;
    ////padding: 10px 15px;
    height: 40px;
    line-height: 40px;
    margin-top:10px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #b1b1b1;
  }

  a {
    font-size: 12px;
    text-transform: none;

  }

}
