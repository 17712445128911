.items-search .search-title {
  font-size: 12px;
  line-height: 16px;
  padding: 2px;
  font-weight: bold;
  text-transform: capitalize;
}

md-list-item .item-draft-offline{
  border: dashed red 2px;
}



.items-search .search-img img {
  width: 100%;
}

.items-search .search-items ul {
  margin: 0 0 0 20px;
  padding: 1px;
}

.items-search .search-items li {
  padding: 2px;
  font-size: 10px;
  line-height: 14px;
  list-style: none;

  div {
    padding: 2px;
    width: 100%;
  }

  .item-online {
    background-color: #dff5df;
  }

  .item-offline {
    background-color: #f6c7c9;
  }

  .article-draft-offline {
    border: dashed red 2px;
  }

}

.items-search .search-item-number {
  font-weight: bold;
  color: #e00000;
}

.list-item-title:hover {
  color: #e00000;
  cursor: pointer;
}

.list-item-img:hover {
  cursor: pointer;
}
