// pdg-catalog-2017-2018

.pdg-catalog-2017-2018 {

  .catalog-chapter {

    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    font-size: 7pt;
    text-transform: uppercase;
    width: 25mm;
    height: 7mm;
    transform-origin: 26mm 0.3mm;
    transform: rotate(-90deg);
    border-radius: 0px 0px 12px 12px;
    -moz-border-radius: 0px 0px 12px 12px;
    -webkit-border-radius: 0px 0px 12px 12px;
    border: 1px solid #000000;

    .en {
      font-weight: bold;

    }

    .fr {
      color: #838383;

    }

  }

  .catalog-section {

    background: #000000;
    color: #ffffff;
    line-height: 24pt;
    font-size: 11pt;
    font-weight: bold;
    text-transform: uppercase;

    .section-logo {
      background-color: #ed1d25;
      color: #ffffff;
      text-align: center;
      font-size: 16pt;
      width: 14mm;
      flex: initial;
      height: 100%;
      vertical-align: bottom;
      line-height: 24pt;
      font-weight: bold;
      display: inline-block;
      margin-right: 1mm;
    }
  }

  .catalog-category {
    margin: 2mm 0;
    background-color: #ed1d25;
    color: #ffffff;
    text-align: center;
    font-size: 12pt;
    text-transform: uppercase;
    line-height: 18pt;
    font-weight: bold;

  }


  .item-specs {

    font-size: 8pt;
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    tr:nth-child(even) {
      background: #e9e9e9
    }

    tr:nth-child(odd) {
      background: #FFFFFF
    }

    th {
      text-align: left;
    }

    .number {
      color: #ed1d25;
      font-weight: bold;
    }

  }


  @import 'ng-app/admin-app/catalogs-templates/pdg-catalog-2017-2018/single-template.scss';
}

