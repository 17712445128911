items-creator {

  #autocomplete-item {
    margin-right: 1em;

  }

  #autocomplete-group {
    input {
      text-transform: capitalize;
    }
  }
}

.autocomplete-group-template {

  > li {
    border-bottom: 2px dashed #cccccc;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    white-space: normal;
  }

  > li:last-child {
    border-bottom-width: 0;
  }

  .item-title {
    text-transform: capitalize;
    font-weight: bold;
  }

  .item-id {
    font-style: italic;
    color: red;
    font-size: 0.8em;

    span {
      color: #000000;
      font-weight: bold;
    }
  }

  .item-page {
    font-style: italic;
    font-size: 0.8em;
    margin-left: 2px;
  }
}

