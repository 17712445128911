.item-details-title {
  text-transform: uppercase;
}

.items-tabs-details-info {

  .item-numbers {
    ul {
      padding: 0 0 0 18px;
    }

    li {
      margin: 0;
      padding: 0;
      font-size: 9pt;
      list-style: none;

      .item-online {
        background-color: #dff5df;
      }

      .item-offline {
        background-color: #f6c7c9;
      }

      .article-draft-offline {
        border: dashed red 2px;
      }

    }

    .item-id {
      position: relative;
      left: -8px;
      padding: 2px;
      margin: 2px;
      border: 1px solid #b1b0b0;

    }

    .item-catalog {
      color: #ed1d25;
      font-weight: bold;
    }
  }

}

.draft-articles {

  margin-bottom: 15px;

  ol {
    padding-left: 32px;
    list-style-type: decimal;
  }

  li {
    float: left;
    margin: 0 10px;
    padding-right: 10px;
  }


  .draft-online a {
    color: green;
  }

  .draft-offline a {
    color: red;
  }


}
