.items-specs {
  width: 100%;

  .add-column-button{
    text-align: left !important;
  }

  .specs-number {
    text-align: center;
  }

  th, td {
    text-align: center;
  }

  td {
    height: 80px;

  }

  .specs-cpid {
    color: #0D72B2;
    border-top: solid 2px grey;
  }
}

.items-specs .dndDraggingSource {
  display: none;
}

.items-specs .dndPlaceholder {
  /*background-color: #dfe7f6;*/
  /*height: 80px;*/
  width: 110px;
  padding: 5px;
}

.items-specs .specs-number, .atr-qty {
  color: #e00000;
  font-weight: bold;
  position: relative;
}

.items-specs .header-dnd {
  background-color: #0D72B2;
}

.specs-row-handle {
  width: 70px;
}


.specs-row-handle > * {
  margin: 0 !important;
  padding: 0 !important;
}

.article-toolbox {
  width: 1100px;

  .buttonContainer {
    width: 270px;
  }

  .article-count-warning {
    background-color: #ffb0b0;
    color: #000000;
    font-weight: bold;
    border: solid 1px #9a9a9a;

    & > * {
      vertical-align: middle;
    }
  }
}

