// ====================================
// Media Query Mixins
// ====================================
// usage
//.my-box {
//  padding: 10px;
//
//  @include for-desktop-up {
//    padding: 20px;
//  }
//}

@mixin for-phone-only {
  @media (max-width: $for-phone-only) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $for-tablet-portrait-up) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $for-tablet-landscape-up) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: $for-desktop-up) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: $for-big-desktop-up) { @content; }
}

// ====================================
// Common mixins
// ====================================

@mixin default-a() {
  text-decoration: none;
  color: $primary-color;
  &:hover {
    color: $accent-color;
  }
}

// ====================================
// Common Borders Settings
// ====================================

@mixin border-radius-combined($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin border-radius-separated($top-left, $top-right, $bottom-right, $bottom-left) {
  -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
  -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
  -ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
  border-radius: $top-left $top-right $bottom-right $bottom-left;
}

@mixin container-shadow {
  -webkit-box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.49);
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.49);

}

@mixin floating-borders-trick($width, $color) {
  box-shadow: $width 0 0 0 $color,
  0 $width 0 0 $color,
  $width $width 0 0 $color, /* Just to fix the corner */
  $width 0 0 0 $color inset,
  0 $width 0 0 $color inset;
}

@mixin default-button{
  @include border-radius-combined($radius-md);
  border-radius: $radius-md;
  border: $accent-color 1px solid;
  color: $primary-color;
  background-color: #ffffff;
  padding: 0.5em 1em;
  cursor: pointer;
}

@mixin input-button-right{
  position: absolute;
  right: 0;
  top:0;
  bottom: 0;
  z-index: 2;
  @include border-radius-separated(0, $radius-sm, $radius-sm, 0) ;
  border: $secondary-color 1px solid;
  color: $primary-color;
  background-color: $light-background;
  cursor: pointer;
}


@mixin default-input($radius){
  background-repeat: no-repeat;
  background-position: right center;
  @include border-radius-combined($radius);
  border: $secondary-color 1px solid;
  color:$primary-color;
  padding: 0.25em;
}