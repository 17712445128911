
.orders-items-table{

  th, td {
    text-align: left;
    line-height: 30px;
  }

  .description {
    text-transform: capitalize;
  }



}