no-cp {

  .scroll-bar-spacer{
    width: 25px;
  }

  .reports-table{

    td:nth-child(1) {
      width: 10%;
    }

    td:nth-child(2) {
      //width: 50%;
    }

    td:nth-child(3) {
      width: 7%;
    }

    td:nth-child(4) {
      width: 10%;
    }

    td:nth-child(5) {
      width: 15%;
    }

    .description{
      font-size: 0.8em;
      text-transform: capitalize;
    }


  }

}
