items-media-youtube {

  .dndDraggingSource {
    display: none;
  }

  ul[dnd-list], .dnd-trash ul[dnd-list] {
    min-height: 80px;
    padding-left: 0px;
    margin: 0;
    display: block;
  }

  ul[dnd-list] .dndDraggingSource, .dnd-trash ul[dnd-list] .dndDraggingSource {
    display: none;
  }

  ul[dnd-list] .dndPlaceholder {
    background-color: #90b4ce;
    height: 215px;
  }

  ul[dnd-list] li {
    border: 1px solid #686868;
    border-radius: 5px;
    list-style-type: none;
    margin: 10px 0;
  }

  ul[dnd-list] li.selected {
    background-color: #e1e1e1;
  }
}






