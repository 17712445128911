.items-picto {
  width: 100%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: flex-start;
  gap: 10px;

  .picto-label {
    //display: flex;
    width: 40px;
    height: 40px;
  }

  input {
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;
    padding: 10px;
    border: solid 1px #0c50ae;

    @include border-radius-combined(10px);
    //@include container-shadow;

    color: #ff1b66;
    fill: #ff1b66;
  }

  input:checked + .toggle-picto {
    background-color: #a8cefc;
  }


}
