.single-template {

  width: 96mm;
  border: 1px solid #000000;
  padding: 2mm;
  background: #ffffff;
  position: relative;


  .single-title {
    text-transform: uppercase;
    font-size: 8pt;
    font-weight: bold;

    &.en {
      color: #000000;
    }

    &.fr {
      color: #9c9c9c;
    }

  }

  .single-extra {
    font-size: 8pt;
  }

  .single-img-container {

    position: relative;

    .single-img {
      width: 100%;
    }

    .ce-logo {
      position: absolute;
      bottom: 5px;
      right: 5px;
      width: 18mm;

    }

    .greenseal-logo {
      position: absolute;
      bottom: 5px;
      right: 5px;
      width: 18mm;

    }

    .recycled-logo {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 18mm;
    }
  }


}


