draft-item, .draft-item {
  .single-template {

    width: 96mm;
    border: 1px solid #000000;
    padding: 2mm;
    background: #ffffff;
    position: relative;


    .single-title {
      text-transform: uppercase;
      font-size: 8pt;
      font-weight: bold;

      &.en {
        color: #000000;
      }

      &.fr {
        color: #9c9c9c;
      }

    }

    .single-extra {
      font-size: 8pt;
      line-height: 10pt;
      margin-bottom: 6pt;
      margin-top: 2pt;
    }

    .info-bar {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      //background-color: #cacaca;
      width: 96mm;
      height: 10mm;

      .red-icon {
        color: #e73111;
      }

      .green-icon {
        color: #52a923;
      }

      .draft-item-error{
        width: 50%;
        margin-top: 2mm;
        padding: 4px;
        background-color: #e73111;
        text-align: center;
        color: #FFFFFF;
        font-weight: bold;
      }

      md-tooltip .md-content {
        height: auto;
      }

    }

    .single-img-container {

      position: relative;

      .single-img {
        width: 100%;
      }

      .ce-logo {
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 18mm;

      }

      .greenseal-logo {
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 18mm;

      }

      .recycled-logo {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 18mm;
      }
    }

    .disabled-draft-item {
      background-color: rgba(0, 0, 0, 0.51);
      left: 0;
      right: 0;
      top:0;
      bottom: 0;
      position: absolute;
      z-index: 10;
      font-weight: bold;
      font-size: 24px;
      color: #ed1d25;
    }

  }

  .item-specs {

    font-size: 8pt;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    tr:nth-child(even) {
      background: #e9e9e9
    }

    tr:nth-child(odd) {
      background: #FFFFFF
    }

    th {
      text-align: left;
    }

    .number {
      color: #ed1d25;
      font-weight: bold;
    }

    .specs-error {
      background-color:#ed1d25;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 16px;

    }
  }

}
