.draft-levels-title {
  background-color: #e73111;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px;
  color: #FFFFFF;
  margin-bottom: 5px;
}

.draft-catalog {


  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
    }
  }

  /**
   * For the correct positioning of the placeholder element, the dnd-list and
   * it's children must have position: relative
   */
  ul[dnd-list],
  ul[dnd-list] > li {
    position: relative;
  }

  /**
   * The dnd-list should always have a min-height,
   * otherwise you can't drop to it once it's empty
   */
  ul[dnd-list] {
    min-height: 42px;
    //padding-left: 0px;
  }

  /**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
  ul[dnd-list] .dndDraggingSource {
    display: none;
  }

  /**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
  ul[dnd-list] .dndPlaceholder {
    border: 4px dashed #5e5e5e;
    //display: block;
    //background-color: #6bc2ef;
    ////padding: 10px 15px;
    height: 200px;
    font-size: 30px;
    font-weight: bold;
    color: #b1b1b1;
  }


}

.draft-nav {
  padding: 0;
  margin: 3px;
  cursor: pointer;
  a:hover {
    color: #62cfff;
  }

  .draft-nav-level1 {
    text-transform: uppercase;
    background-color: #e73111;
    margin-bottom: 2px;
    padding: 2px;
    font-weight: bold;
    color: #FFFFFF;
    font-size: 15px;
    md-icon {
      color: #FFFFFF;

    }
  }

  .draft-nav-level2 {
    text-transform: uppercase;
    color: #FFFFFF;
    //font-weight: bold;
    font-size: 15px;
    background-color: #000000;
    margin-bottom: 2px;
    padding: 2px;
    md-icon {
      color: #FFFFFF;
    }


  }
  .draft-nav-level3 {
    text-transform: capitalize;
    color: #000000;
    font-size: 14px;
    margin-bottom: 2px;
    padding: 2px;
  }

  ul[dnd-list] .dndPlaceholder {
    border: 2px dashed #ff3f27;
    //display: block;
    background-color: #f2f2f2;
    padding: 10px 15px;
    //height: 50px;
    //font-size: 30px;
    //font-weight: bold;
    color: #ff3f27;
  }


}
