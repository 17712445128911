cp-orphans {

  .scroll-bar-spacer{
    width: 25px;
  }

  .report-filter {
    position: relative;
    margin: 0 5px;
    width: 200px;
    .filter-icon {

      position: absolute;
      left: 1px;
      top: 1px;
    }

    .clear-button {
      position: absolute;
      right: 1px;
      top: 1px;
      cursor: pointer;

    }
    input {
      height: 19px;
      font-size: 20px;
      padding-left: 30px;
    }

  }

  .reports-table{

    td:nth-child(1) {
      width: 15%;
    }

    td:nth-child(2) {
      width: 15%;
    }

    td:nth-child(3) {
      //width: 10%;
    }

    td:nth-child(4) {
      width: 5%;
    }

    td:nth-child(5) {
      width: 5%;
    }

    .description{
      font-size: 0.8em;
      text-transform: capitalize;
    }


  }

}
