body {
  overflow: hidden;
  background-color: #EEEEEE;
}

.md-input-container .md-errors-spacer {
  min-height: 0 !important;
}

.md-whiteframe {
  background: #fff;
}

.md-button.md-fab.md-micro {
  line-height: 24px;
  width: 24px;
  height: 24px;
  min-height: 24px;
}

.md-button.md-fab.md-micro md-icon {
  font-size: 16px !important;
}


#content {
  padding: 24px;
}

.white-icon {
  color: #ffffff;
}

.required-symbol {
  color: #ff0e1b;
  font-weight: bold;
}

.hover-click-cursor:hover {
  cursor: pointer;
}

.rds-thin-toolbar {
  height: 30px;
  min-height: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 0 10px;

  .md-toolbar-tools{
    height: 30px;
    min-height: 30px;
    margin: 0;
    padding: 0;

  }

  h5 {
    margin: 0;
  }

}

.rds-thin-toolbar md-switch, .rds-thin-toolbar md-button {
  margin: 0;
  font-size: 12px;
}

.rds-fab-positioning {
  position: absolute;
  right: 1px;
  top: 26px;
  width: 35px;
  height: 20px;
}
