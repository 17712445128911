.items-search-dlg {

  .search-templates {
    background: #ffffff;
    padding: 4px;
    margin: 1px;

    @include border-radius-combined(5px);
  }

  .adv-filters{

    md-input-container{
      margin: 8px auto !important;
    }

  }
}
